class ProductAnimation {
  timeouts = []
  elements = []
  animations = []

  showAddToCartAnimation(sourceElement, destinationElement = document.querySelector('.cart-btn'), delay = 0) {
    if (sourceElement) {
      const animationDelayTimeout = setTimeout(() => {
        const clone = this.cloneAnimatedElement(sourceElement)          
        this.attachAnimatedElementToBody(clone)
        this.animateElementToDestination(clone, destinationElement)
      }, delay) 
      this.timeouts.push(animationDelayTimeout)
    }
  }
  clear() {
    this.clearAnimations()
    this.clearTimeouts()
    this.clearElements()
  }
  cloneAnimatedElement(element) {
    const clone = element.cloneNode()
    clone.style.top = `${element.getBoundingClientRect().top + window.scrollY + element.offsetHeight/6}px`
    clone.style.left = `${element.getBoundingClientRect().left + window.scrollX + element.offsetWidth/6}px`
    clone.style.opacity = 0.8 
    clone.style.position = 'absolute'
    clone.style.height = `${element.offsetHeight/1.5}px`
    clone.style.width = `${element.offsetWidth/1.5}px`
    clone.style.zIndex = 100000
    clone.style.borderRadius = '18px'
    return clone
  }
  attachAnimatedElementToBody(element) {
    const body = document.querySelector('body')
    body.appendChild(element)
    this.elements.push(element)
  }
  animateElementToDestination(element, destinationElement) {
    const body = document.querySelector('body')
    if (destinationElement) {
      const animation = element.animate({
        'top': `${destinationElement.getBoundingClientRect().top + window.scrollY + destinationElement.offsetHeight/2}px`,
        'left': `${destinationElement.getBoundingClientRect().left + window.scrollX + destinationElement.offsetWidth/2}px`,
        'width': 0,
        'height': 0,
        'opacity': 0
      }, 500, 'linear');
      this.animations.push(animation)
      
      const removeChildTimeout = setTimeout(() => {
        body.removeChild(element)
      }, 400);
      this.timeouts.push(removeChildTimeout)
    }
  }

  clearAnimations() {
    this.animations.forEach(animation => {
      animation.cancel()
    })
    this.animations = []
  }
  clearTimeouts() {
    this.timeouts.forEach(timeout => {
      clearTimeout(timeout)
    })
    this.timeouts = []
  }
  clearElements() {
    const body = document.querySelector('body')
    this.elements.forEach(element => {
      if (body.contains(element)) {
        body.removeChild(element)
      }
    })
    this.elements = []
  }
}

export default ProductAnimation