import { Controller } from "@hotwired/stimulus"
import '@splidejs/splide/css';
import Splide from '@splidejs/splide';

export default class extends Controller {
  connect() {
    new Splide('.splide', {
      type: 'loop',
      width: '100%',
      pagination: false,
      heightRatio: 0.34,
      autoplay: true,
      interval: 3500,
      speed: 1200,
      gap: '30px'
    }).mount();
  }
}