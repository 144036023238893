import { application } from "./application"

import ProductsController from "./products_controller.js"
application.register("products", ProductsController)

import ProductModalController from "./product_modal_controller.js"
application.register("product-modal", ProductModalController)

import CartController from "./cart_controller.js"
application.register("cart", CartController)

import CartModalController from "./cart_modal_controller.js"
application.register("cart-modal", CartModalController)

import ErrorModalController from "./error_modal_controller.js"
application.register("error-modal", ErrorModalController)

import LeftMenuController from "./left_menu_controller.js"
application.register("left-menu", LeftMenuController)

import CarouselController from "./carousel_controller.js"
application.register("carousel", CarouselController)

import CartGiftsController from "./cart_gifts_controller.js"
application.register("cart-gifts", CartGiftsController)

import CartRecommendationsController from "./cart_recommendations_controller"
application.register("cart-recommendations", CartRecommendationsController)

import GoBackController from "../../controllers/go_back_controller.js"
application.register("go-back", GoBackController)

import DownloadAppBannerController from "../../controllers/download_app_banner_controller"
application.register("download-app-banner", DownloadAppBannerController)