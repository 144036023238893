import { Controller } from "@hotwired/stimulus"
import ProductAnimation from '../animations'
import LazyLoad from "vanilla-lazyload";

export default class extends Controller {
  static targets = [ 'productActionBtn', 'productModal' ]
  products = []
  waitingForOpenProductId = null
  waitingForAddingProductIds = []
  abortController = new AbortController()
  animation = new ProductAnimation()

  connect() {
    this.loadProducts()
    this.checkProductInParams()
    window.addEventListener('popstate', this.onPopState);
    window.addEventListener('turbo:frame-load', this.onFrameLoaded);
    new LazyLoad({
      threshold: 700
    });
  }

  disconnect() {
    this.abortController.abort()
    window.removeEventListener('popstate', this.onPopState);
    window.removeEventListener('turbo:frame-load', this.onFrameLoaded);
    this.animation.clear()
  }
  onFrameLoaded = e => {
    if (e.target.id !== 'old_prices') return
    this.loadProducts()
    this.checkProductInParams()
    new LazyLoad();
  }
  onPopState = e => {
    this.checkProductInParams()
  }
  loadProducts() {
    this.isLoading = true
    let params = this.productActionBtnTargets.map(product => `ids[]=${product.getAttribute('data-products-id-param')}`)
    fetch(`/web_api/products.json?${params.join('&')}`, { signal: this.abortController.signal })
      .then(response => response.json())
      .then(json => {
        this.products = json.products.reduce((map, obj) => {
          map[obj.id] = obj;
          return map;
        }, {});

        this.additionsSets = json.additions_sets.reduce((map, obj) => {
          map[obj.id] = obj.additions;
          return map;
        }, {});
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.isLoading = false
        this.checkWaitingForModalProduct()
        this.checkWaitingForCartProducts()
      })
  }
  checkWaitingForModalProduct() {
    if (this.waitingForOpenProductId) {
      const waitingProdictId = this.waitingForOpenProductId;
      this.waitingForOpenProductId = null;
      this.findProductAndOpenModal(waitingProdictId)
    }
  }
  checkWaitingForCartProducts() {
    if (this.waitingForAddingProductIds.length) {
      const waitingProdictIds = this.waitingForAddingProductIds
      this.waitingForAddingProductIds = []
      waitingProdictIds.forEach(productId => {
        this.findProductAndAddToCart(productId)
      })
    }
  }
  openProductModal(e) {
    e.preventDefault()
    e.stopPropagation()
    this.findProductAndOpenModal(e.params.id)
  }
  findProductAndOpenModal(productId, restoreState = false) {
    const selectedProduct = this.products[productId]
    if (selectedProduct) {    
      this.sendOpenProductModalEvent(selectedProduct, restoreState)
    } else {
      this.handleOpenModalError(productId)
    }
  }
  sendOpenProductModalEvent(selectedProduct, restoreState = false) {
    selectedProduct.variants.forEach(variant => {
      variant.additions = this.additionsSets[variant.additions_set_id] || []
    })
    this.dispatch('openModal', { 
      detail: { 
        product: selectedProduct,
        restoreState: restoreState
      } 
    })
  }
  handleOpenModalError(productId) {
    if (this.isLoading) {
      this.waitingForOpenProductId = productId
    } else {
      this.dispatch('openErrorModal')
    }
  }
  addToCart(e) {
    e.preventDefault()
    e.stopPropagation()
    this.findProductAndAddToCart(e.params.id)
  }
  findProductAndAddToCart(productId) {
    const selectedProduct = this.products[productId]
    if (selectedProduct) {    
      this.sendAddToCartEvent(selectedProduct)
      this.animation.showAddToCartAnimation(
        document.querySelector(`a[data-products-id-param="${productId}"] img.actual`)
      )
    } else {
      this.handleAddToCartError(productId)
    }
  }
  showAddToCartAnimation(e) {
    this.animation.showAddToCartAnimation(
      document.querySelector(`a[data-products-id-param="${e.detail.product.id}"] img.actual`),
      document.querySelector('.cart-btn'), 
      200
    )
  }
  sendAddToCartEvent(selectedProduct) {
    const variant = selectedProduct.variants[0]
    const payload = { 
      product: {
        id: selectedProduct.id,
        title: selectedProduct.title,
        image: selectedProduct.placeholder_image,
        currency: variant.currency,
        variant: {
          id: variant.id,
          base_price_cents: variant.base_price_cents,
          title: variant.title,
          weight: variant.weight
        },
        additions: []
      }
    } 
    this.dispatch('addToCart', { 
      detail: payload
    })
  }
  handleAddToCartError(productId) {
    if (this.isLoading) {
      this.waitingForAddingProductIds.push(productId)
    } else {
      this.dispatch('openErrorModal')
    }
  }
  checkProductInParams() {
    const productId = new URLSearchParams(window.location.search).get('product');
    const productElement = document.querySelector(`.product[data-product-id='${productId}']`)
    if (productId) {
      if (!productElement) {
        return
      }
      productElement.scrollIntoView({ block: "center" })
      if (this.hasProductModalTarget && this.productModalTarget.classList.contains('v-hidden')) {
        window.history.replaceState(
          {}, 
          document.title, 
          location.protocol + '//' + location.host + location.pathname
        )
        this.findProductAndOpenModal(productId, true)
      }
    } else {
      if (this.hasProductModalTarget && !this.productModalTarget.classList.contains('v-hidden')) {
        this.dispatch('closeProductModal', {
          detail: {
            restoreState: true
          }
        })
      }
    }
  }
  showSelectedProducts(e) {
    const changedProductIds = e.detail.changedProductIds
    this.productActionBtnTargets.forEach(product => {
      const id = parseInt(product.getAttribute('data-products-id-param'))
      if (changedProductIds.includes(id)) {
        product.classList.add('selected')
      } else {
        product.classList.remove('selected')
      }
    })
  }
}
