import { Controller } from "@hotwired/stimulus"
import { loadOrder } from '../../order_storage'

export default class extends Controller {
  static targets = [ 
    'cartRecommendationsFrame'
  ]
  static values = {
    url: String
  }

  connect() {
    this.loadFrame()
  }
  loadFrame() {
    const url = new URL(this.urlValue)
    const order = loadOrder()
    order.productVariants.forEach(orderItem => {
      url.searchParams.append("product_variant_ids[]", orderItem.variant.id);
    })
    this.cartRecommendationsFrameTarget.src = url
  }
  addRecommendedProductToCart(e) {
    e.preventDefault()
    e.stopPropagation()

    const payload = {
      product: {
        additions: [],
        currency: e.params.currency,
        id: e.params.id,
        image: e.params.image,
        notAvailable: false,
        gifted: false,
        quantity: 1,
        title: e.params.title,
        uniqId: `${e.params.id}_${e.params.variantId}_`,
        variant: {
          base_price_cents: e.params.variantBasePriceCents,
          id: e.params.variantId,
          weight: e.params.variantWeight
        }
      }
    }

    this.dispatch('addToCart', { 
      detail: payload
    })
    this.dispatch('updateCartModal', {
      detail: {
        order: loadOrder()
      }
    })
  }
}