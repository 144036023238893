import { Controller } from "@hotwired/stimulus"
import toCurrencyFormat from '../../number_formatter'
import { createCart } from "../../cart_creator"

export default class extends Controller {
  static targets = [ 
    'body', 'modal', 'modalBody', 'cartBackground',
    'totalPrice',
    'orderItems'
  ]
  static values = {
    notAvailableText: String,
    deleteText: String,
    giftText: String,
    forCashbackText: String,
    forPromoCodeText: String
  }
  order = createCart()
  restoreState = null

  openModal(e) {
    e.preventDefault()
    e.stopPropagation()
    this.order = e.detail.order
    this.populateModal()
    this.setCartUrl(e.detail.restoreState)
    this.cartBackgroundTarget.classList.remove('o-0')
    this.cartBackgroundTarget.classList.remove('v-hidden')
    this.modalBodyTarget.classList.add('open')
    this.bodyTarget.classList.add('stuck')
  }
  updateModal(e) {
    e.preventDefault()
    e.stopPropagation()
    this.order = e.detail.order
    this.populateModal()
  }
  closeModal(e) {
    e.preventDefault()
    e.stopPropagation()
    if (e.currentTarget !== e.target) {
      return
    }
    this.clearAndCloseModal(e)
  }
  clearAndCloseModal(e) {
    this.modalBodyTarget.classList.remove('open')
    this.cartBackgroundTarget.classList.add('o-0')
    this.cartBackgroundTarget.classList.add('v-hidden')
    this.bodyTarget.classList.remove('stuck')
    this.modalTarget.classList.remove('empty')
    this.order = createCart()

    let restoreState = this.restoreState
    if (e && e.detail && e.detail.restoreState !== undefined) {
      restoreState = e.detail.restoreState
    }
    this.clearCartUrl(restoreState)
  }
  setCartUrl(restoreState) {
    this.restoreState = restoreState
    if (!restoreState) {
      window.history.pushState(
        {}, 
        document.title, 
        '?page=cart'
      )
    }
  }
  clearCartUrl(restoreState) {
    if (!restoreState) {
      window.history.back()
    }
  }
  populateModal() {
    if (!this.order.productVariants.length) {
      this.modalTarget.classList.add('empty')
      return
    }

    this.totalPriceTargets.forEach(target => {
      const totalPriceCents = this.order.productVariants.reduce((totalPrice, orderItem) => {
        return totalPrice + this.calculateItemPrice(orderItem)
      }, 0)
      target.innerHTML = `${this.formatPrice(totalPriceCents)} `
    })

    this.orderItemsTarget.innerHTML = this.order.productVariants.map(orderItem => {
      let priceOrText = `
        <div class='price'>
          ${this.calculateAndFormatItemPrice(orderItem)} ${orderItem.currency}
        </div>
      `
      if (orderItem.notAvailable) {
        priceOrText = `
          <div class='not-available-text'>
            ${this.notAvailableTextValue}
          </div>
        `
      }
      if (orderItem.gifted) {
        priceOrText = `
          <div class='gifted-text'>
            ${this.giftTextValue}
          </div>
        `
      }
      if (orderItem.forCashback) {
        priceOrText = `
          <div class='gifted-text'>
            ${this.forCashbackTextValue}
          </div>
        `
      }
      if (orderItem.forPromoCode) {
        priceOrText = `
          <div class='gifted-text'>
            ${this.forPromoCodeTextValue}
          </div>
        `
      }
   
      const controlsOrDelete = orderItem.notAvailable || (orderItem.gifted || orderItem.forCashback || orderItem.forPromoCode) ? `
        <div class='delete-item'>
          <button class='btn red-outline'
                  data-action='click->cart#deleteItem'
                  data-cart-item-uniq-id-param='${orderItem.uniqId}'
          >
            ${this.deleteTextValue}
          </button>
        </div>
      ` : `
        <div class='count-controlls'>
        <div class='control no-select' 
            data-action='click->cart#minusItemQuantity'
            data-cart-item-uniq-id-param='${orderItem.uniqId}'
        >−</div>
        <div class='count no-select'>${orderItem.quantity}</div>
        <div class='control no-select' 
            data-action='click->cart#plusItemQuantity'
            data-cart-item-uniq-id-param='${orderItem.uniqId}'
        >+</div>
        </div>
      `

      return `
      <div class='order-item ${orderItem.notAvailable ? 'not-available' : ''}'>
        <div class='top'>
          <div class='left'>
            <img class='image' src='${orderItem.image}'>
          </div>
          <div class='right'>
            <div class='title'>
              ${orderItem.title}
            </div>
            <div class='info'>
             ${this.infoDiv(orderItem)}
            </div>
          </div>
        </div>
        <div class='bottom'>
          ${priceOrText}
          ${controlsOrDelete}
        </div>
      </div>
      <div class='divider'></div>
      `
    }).join('')
  }
  infoDiv(orderItem) {
    let titleAndWeight = orderItem.variant.weight
    if (orderItem.variant.title) {
      titleAndWeight = `${orderItem.variant.title}, ${titleAndWeight}`
    }

    let additions = orderItem.additions.map(addition => addition.title).join(', ')
    if (additions) {
      additions = `+ ${this.capitalize(additions)}`
    }

    return `
      <div>${titleAndWeight}</div>
      <div>${additions}</div>
    `
  }
  capitalize(str) {
    const lower = str.toLowerCase();
    return str.charAt(0).toUpperCase() + lower.slice(1);
  }
  calculateAndFormatItemPrice(item) {
    return this.formatPrice(this.calculateItemPrice(item))
  }
  formatPrice(priceCents) {
    return toCurrencyFormat(priceCents / 100)
  }
  calculateItemPrice(item) {
    if (item.notAvailable) return 0
    return (item.variant.base_price_cents + this.calculateAdditionsPrice(item.additions)) * item.quantity
  }
  calculateAdditionsPrice(additions) {
    return additions.reduce((additionsPrice, addition) => {
      return additionsPrice + addition.base_price_cents;
    }, 0)
  }
  processOrder(e) {
    Turbo.visit(e.params.checkoutUrl, { action: 'replace' })
  }
}
