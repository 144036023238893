import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 
    'menu', 'menuItem'
  ]

  connect() {
    this.scrollToActiveMenuItem()
    this.handleMenuShadow()
  }
  scrollToActiveMenuItem() {
    const activeMenuItem = this.menuItemTargets.find(menuItem => menuItem.classList.contains('active'))
    if (activeMenuItem) {
      this.menuTarget.scrollLeft = 
        activeMenuItem.getBoundingClientRect().left - (window.innerWidth / 2) + activeMenuItem.offsetWidth/2
    }
  }
  handleMenuShadow() {
    window.onscroll = () => {
      if (window.pageYOffset < 48) {
        this.menuTarget.classList.remove("shadow");
      } else {
        this.menuTarget.classList.add("shadow")
      }
    }
  }
}
