import { Controller } from "@hotwired/stimulus"
import toCurrencyFormat from '../../number_formatter'
import LazyLoad from "vanilla-lazyload";

export default class extends Controller {
  static targets = [ 
    'body', 'modal', 'modalBackground', 'modalBody',
    'fullImage', 'productTitle', 'productWeight', 'productDescription',
    'variants', 'variant',
    'additionsLabel', 'additions', 'addition',
    'toCartButtonLabel'
  ]
  selectedProduct = null
  selectedVariant = null
  selectedAdditions = []
  restoreState = null
  lazyLoader = null

  openModal(e) {
    e.preventDefault()
    e.stopPropagation()
    this.selectedProduct = e.detail.product
    this.selectedVariant = this.selectedProduct.variants[0]
    this.selectedAdditions = []
    this.setProductUrl(e.detail.restoreState)
    this.populateModal(this.selectedProduct)
    this.modalTarget.classList.remove('v-hidden')
    this.modalBackgroundTarget.classList.remove('o-0')
    this.modalBodyTarget.classList.add('zoomIn')
    this.bodyTarget.classList.add('stuck')
  }
  setProductUrl(restoreState) {
    this.restoreState = restoreState
    if (!restoreState) {
      window.history.pushState(
        {}, 
        document.title, 
        `?product=${this.selectedProduct.id}`
      )
    }
  }
  clearProductUrl(restoreState) {
    if (!restoreState) {
      window.history.back()
    }
  }
  chooseVariant(e) {
    this.selectedVariant = this.selectedProduct.variants.find(variant => variant.id == e.params.variantId)
    this.handleVariantsActiveLabels(e.target)
    this.transferSelectedAdditions()
    this.populateAddToCartButton()
    this.populateWeight()
    this.populateAdditions(0)
  }
  handleVariantsActiveLabels(selectedTarget) {
    this.variantTargets.forEach(variant => {
      variant.classList.remove('active')
    })
    selectedTarget.classList.add('active')
  }
  transferSelectedAdditions() {
    this.selectedAdditions = this.selectedVariant.additions.filter(newAddition => {
      return this.selectedAdditions.some(addition => addition.title.toLowerCase() === newAddition.title.toLowerCase())
    })
  }
  chooseAddition(e) {
    const additionsSet = this.selectedVariant.additions
    const selectedAddition = additionsSet.find(addition => addition.id == e.params.additionId)
    this.handleAdditionsActiveLabels(e.currentTarget, selectedAddition)
    this.handleAdditionsLimit()
    this.populateAddToCartButton()
  }
  handleAdditionsActiveLabels(selectedTarget, selectedAddition) {
    if (this.selectedAdditions.some(addition => addition.id == selectedAddition.id)) {
      selectedTarget.classList.remove('active')
      this.selectedAdditions = this.selectedAdditions.filter(addition => addition.id != selectedAddition.id)
    } else {
      selectedTarget.classList.add('active')
      this.selectedAdditions.push(selectedAddition)
    }
  }
  handleAdditionsLimit() {
    if (this.selectedAdditions.length >= 3) {
      this.additionTargets.forEach(addition => {
        if (!addition.classList.contains('active')) {
          addition.classList.add('disabled')
        }
      })
    } else {
      this.additionTargets.forEach(addition => {
        addition.classList.remove('disabled')
      })
    }
  }
  calculateTotalPrice() {
    const totalPrice = this.selectedAdditions.reduce((sum, addition) => { 
      return sum + addition.base_price_cents 
    }, this.selectedVariant.base_price_cents); 
    return toCurrencyFormat(totalPrice / 100)
  }
  closeModal(e) {
    e.preventDefault()
    e.stopPropagation()
    if (e.currentTarget !== e.target) {
      return
    }
    this.clearAndCloseModal(e)
  }
  clearAndCloseModal(e) {
    setTimeout(() => {
      this.modalTarget.classList.add('v-hidden')
      this.modalBodyTarget.classList.remove('zoomOut')
      this.bodyTarget.classList.remove('stuck')
      this.productTitleTarget.scrollIntoView({ block: "start" })
      this.selectedProduct = null
      this.selectedVariant = null
      this.selectedAdditions = []
      this.productTitleTarget.innerHTML = ''
      this.productWeightTarget.innerHTML = ''
      this.productDescriptionTarget.innerHTML = ''
      this.variantsTarget.classList.add('d-none')
      this.additionsTarget.classList.add('d-none')
      this.additionsLabelTarget.classList.add('d-none')
      this.toCartButtonLabelTarget.innerHTML = ''
      this.fullImageTarget.src = ''
      this.fullImageTarget.srcset = ''
      this.fullImageTarget.setAttribute('data-srcset', '')
      this.fullImageTarget.className = 'lazy-product-modal'
      this.lazyLoader.update()
  
      let restoreState = this.restoreState
      if (e && e.detail && e.detail.restoreState !== undefined) {
        restoreState = e.detail.restoreState
      }
      this.clearProductUrl(restoreState)
    }, 140)

    this.modalBackgroundTarget.classList.add('o-0')
    this.modalBodyTarget.classList.remove('zoomIn')
    this.modalBodyTarget.classList.add('zoomOut')
   
  }
  populateModal(product) {
    this.lazyLoader = new LazyLoad({
      container: this.modalBodyTarget,
      elements_selector: '.lazy-product-modal'
    });
    this.lazyLoader.restoreAll()
    this.fullImageTarget.src = product.placeholder_image
    this.fullImageTarget.setAttribute('data-srcset', `${product.thumb_image} 450w, ${product.full_image}`)
    this.lazyLoader.update()
    this.productTitleTarget.innerHTML = product.title
    this.productDescriptionTarget.innerHTML = product.description
    this.populateWeight()
    this.populateVariants(product)
    this.populateAdditions()
    this.populateAddToCartButton()
  }
  populateWeight() {
    this.productWeightTarget.innerHTML = this.selectedVariant.weight
  }
  populateVariants(product) {
    if (product.variants.length > 1) {
      const optionsClass = product.variants.length === 3 ? 'three-options' : 'two-options'
      this.variantsTarget.innerHTML = product.variants.map((variant, index) => {
        return `
          <div class='variant no-select ${optionsClass} ${index == 0 ? 'active' : ''}'
               data-action='click->product-modal#chooseVariant'
               data-product-modal-variant-id-param='${variant.id}'
               data-product-modal-target='variant'
          >${variant.title}</div>
        `
      }).join('')
      this.variantsTarget.classList.remove('d-none')
    } else {
      this.variantsTarget.classList.add('d-none')
    }
  }
  populateAdditions() {
    const additionsSet = this.selectedVariant.additions
    if (additionsSet.length) {
      this.additionsTarget.innerHTML = additionsSet.map(addition => {
        return `
        <div class='addition-container'>
          <div class='addition no-select ${this.selectedAdditions.some(selectedAddition => { 
            return selectedAddition.id == addition.id
          }) ? 'active' : '' }'
              data-addition-id='${addition.id}'
              data-action='click->product-modal#chooseAddition'
              data-product-modal-addition-id-param='${addition.id}'
              data-product-modal-target='addition'
          >
            <div class='addition-wrapper'>
              <div class='image'>
                <img src='${addition.image}'>
              </div>
              <div class='right-section'>
                <div class='addition-title'>${addition.title}</div>
                <div class='addition-base-price'>${(addition.base_price_cents / 100)} ${addition.currency}</div>
              </div>
            </div>
          </div>
        </div>
        `
      }).join('')
      this.handleAdditionsLimit()
      this.additionsTarget.classList.remove('d-none')
      this.additionsLabelTarget.classList.remove('d-none')
    } else {
      this.additionsTarget.classList.add('d-none')
      this.additionsLabelTarget.classList.add('d-none')
    }
  }
  populateAddToCartButton() {
    this.toCartButtonLabelTarget.innerHTML = 
    `${this.calculateTotalPrice()} ${this.selectedVariant.currency}`
  }
  addToCart() {
    const payload = { 
      product: {
        id: this.selectedProduct.id,
        title: this.selectedProduct.title,
        image: this.selectedProduct.placeholder_image,
        currency: this.selectedVariant.currency,
        variant: {
          id: this.selectedVariant.id,
          base_price_cents: this.selectedVariant.base_price_cents,
          title: this.selectedVariant.title,
          weight: this.selectedVariant.weight
        },
        additions: this.selectedAdditions.map(addition => {
          return { 
            id: addition.id,
            title: addition.title,
            base_price_cents: addition.base_price_cents
          }
        })
      }
    } 
    this.dispatch('addToCart', { 
      detail: payload
    })

    this.clearAndCloseModal()
  }
}
