import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  CLOSE_EVENT_KEY = 'close_event_key'

  static values = {
    showOnMobile: Boolean,
    showOnDesktop: Boolean
  }

  connect() {
    if (this.shouldShowBanner()) {
      this.showDownloadAppBanner()
    } else if (!this.withoutMobileAppInParams()) {
      this.saveCloseEvent()
    }
  }
  showDownloadAppBanner() {
    this.element.classList.remove('d-none')
  }
  closeBanner(e) {
    e.preventDefault()
    e.stopPropagation()
    if (e.currentTarget !== e.target) {
      return
    }
    this.element.classList.add('d-none')
    this.saveCloseEvent()
  }
  saveCloseEvent() {
    localStorage.setItem(this.CLOSE_EVENT_KEY, '1')
  }
  shouldShowBanner() {
    return this.withoutMobileAppInParams() && this.withoutSavedCloseEvent() && this.appropriateDevice()
  }
  withoutMobileAppInParams() {
    return !new URLSearchParams(window.location.search).get('mobile_app')
  }
  withoutSavedCloseEvent() {
    return !localStorage.getItem(this.CLOSE_EVENT_KEY)
  }
  appropriateDevice() {
    if (this.showOnMobileValue && this.isMobile()) {
      return true
    }
    if (this.showOnDesktopValue && !this.isMobile()) {
      return true
    }
    return false
  }
  isMobile() {
    return window.screen.width < 500
  }
}