import { Controller } from "@hotwired/stimulus"
import { orderToUrlFormParams } from '../../order_to_url'

export default class extends Controller {
  static targets = [ 
    'cartGiftsFrame'
  ]
  static values = {
    url: String
  }

  connect() {
    this.loadFrame()
  }
  loadFrame() {
    let url = orderToUrlFormParams(this.urlValue)
    this.cartGiftsFrameTarget.src = url
  }
}