import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 
    'modal'
  ]

  openModal(e) {
    e.preventDefault()
    e.stopPropagation()
    this.modalTarget.classList.remove('v-hidden')
  }
  reloadPage() {
    document.location.reload();
  }
}
