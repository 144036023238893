import { Controller } from "@hotwired/stimulus"
import { loadOrder, saveOrder } from '../../order_storage'
import { createCart, CART_VERSION } from "../../cart_creator"

export default class extends Controller {
  static targets = [ 'showCartBtnLabel', 'showCartBtnDidiver', 'cartModal' ]
  order = createCart()
  windowWidth = 0;

  connect() {
    this.windowWidth = window.innerWidth
    this.loadOrder()
    this.updateCartLabel()
    this.sendUpdateEvent()
    this.checkCartInParams()
    window.addEventListener('popstate', this.onPopState);
    window.addEventListener('turbo:frame-load', this.onFrameLoaded);
  }
  disconnect() {
    window.removeEventListener('popstate', this.onPopState);
    window.removeEventListener('turbo:frame-load', this.onFrameLoaded);
  }
  onFrameLoaded = e => {
    if (e.target.id !== 'old_prices') return
    this.sendUpdateEvent()
  }
  onPopState = e => {
    this.checkCartInParams()
  }
  saveOrder() {
    saveOrder(this.order)
  }
  loadOrder() {
    this.order = loadOrder()
    if (this.order.cartVersion != CART_VERSION) {
      this.order = createCart()
    }
    this.saveOrder()
  }
  addToCart(e) {
    const product = e.detail.product
    this.saveProductToStorage(product)
    this.updateCartLabel()
    this.sendUpdateEvent()
  }
  addManyToCart(e) {
    this.clearCart()
    e.detail.products.forEach(product => {
      this.saveProductToStorage(product)
      this.updateCartLabel()
      this.sendUpdateEvent()
    })
    this.sendOpenCartEvent()
  }
  clearCart() {
    this.order = createCart()
    this.saveOrder()
    this.updateCartLabel()
    this.sendUpdateEvent()
  }
  showCart(e) {
    e.preventDefault()
    e.stopPropagation()
    this.sendOpenCartEvent()
  }
  sendOpenCartEvent(restoreState = false) {
    this.dispatch('openModal', { 
      detail: { 
        order: this.order,
        restoreState
      } 
    })
  }
  sendCloseCartEvent(restoreState = false) {
    this.dispatch('closeCartModal', { 
      detail: { 
        restoreState
      } 
    })
  }
  saveProductToStorage(product) {
    if (!product.uniqId) {
      product.uniqId = 
      `${product.id}_${product.variant.id}_${product.additions.map(addition => addition.id).join('_')}`
    }
    
    if (this.order.productVariants.some(orderItem => orderItem.uniqId === product.uniqId)) {
      product = this.order.productVariants.find(orderItem => orderItem.uniqId === product.uniqId)
      product.quantity += 1
      product.notAvailable = false
    } else {
      product.quantity = product.quantity ? product.quantity : 1
      this.order.productVariants.push(product)
    } 

    this.saveOrder()
  }
  updateCartLabel() {
    const cartSize = this.order.productVariants.reduce((size, orderItem) => {
      return size + orderItem.quantity
    }, 0)

    this.showCartBtnLabelTarget.innerHTML = cartSize
    if (cartSize) {
      this.showCartBtnLabelTarget.classList.remove('d-none')
      if (this.windowWidth > 670) {
        this.showCartBtnDidiverTarget.classList.remove('d-none')
      } else {
        this.showCartBtnDidiverTarget.classList.add('d-none')
      }
    } else {
      this.showCartBtnLabelTarget.classList.add('d-none')
      this.showCartBtnDidiverTarget.classList.add('d-none')
    }
  }
  sendUpdateEvent() {
    this.dispatch('cartChanged', { 
      detail: { 
        changedProductIds: this.order.productVariants.map(orderItem => orderItem.id)
      } 
    })
  }
  sendUpdateCartModalEvent() {
    this.dispatch('cartUpdated', {
      detail: {
        order: this.order
      }
    })
  }
  minusItemQuantity(e) {
    const orderItem = this.order.productVariants.find(orderItem => orderItem.uniqId === e.params.itemUniqId)
    orderItem.quantity -= 1
    if (orderItem.quantity <= 0) {
      this.order.productVariants = this.order.productVariants.filter(orderItem => orderItem.uniqId !== e.params.itemUniqId)
      this.saveOrder()
      this.updateCartLabel()
    } else {
      this.updateCartLabel()
      this.saveOrder()
    }
    this.sendUpdateEvent()
    this.sendUpdateCartModalEvent()
  }
  plusItemQuantity(e) {
    const orderItem = this.order.productVariants.find(orderItem => orderItem.uniqId === e.params.itemUniqId)
    orderItem.quantity += 1
    this.saveOrder()
    this.updateCartLabel()
    this.sendUpdateEvent()
    this.sendUpdateCartModalEvent()
  }
  deleteItem(e) {
    this.order.productVariants = this.order.productVariants.filter(orderItem => orderItem.uniqId != e.params.itemUniqId)
    if (!this.order.productVariants.some(orderItem => orderItem.forPromoCode)) {
      this.order.promoCode = null
    }
    this.saveOrder()
    this.updateCartLabel()
    this.sendUpdateEvent()
    this.sendUpdateCartModalEvent()
  }
  onWindowResized(e) {
    this.windowWidth = window.innerWidth
    this.updateCartLabel()
  }
  checkCartInParams() {
    const pageParam = new URLSearchParams(window.location.search).get('page');
    if (pageParam === 'cart') {
      if (this.hasCartModalTarget && !this.cartModalTarget.classList.contains('open')) {
        window.history.replaceState(
          {}, 
          document.title, 
          location.protocol + '//' + location.host + location.pathname
        )
        this.sendOpenCartEvent(true)
      }
    } else {
      if (this.hasCartModalTarget && this.cartModalTarget.classList.contains('open')) {
        this.sendCloseCartEvent(true)
      }
    }
  }
}
