import { createCart } from "./cart_creator"

const CART_KEY = 'cart'
export function loadOrder() {
  const order = JSON.parse(window.localStorage.getItem(CART_KEY)) || createCart();
  // Migration start. Delete it after 01.01.2023
  if (!order.uuid) {
    order.uuid = Date.now();
    saveOrder(order)
  }
  // Migration end
  return order
}
export function saveOrder(order) {
  window.localStorage.setItem(CART_KEY, JSON.stringify(order))
}