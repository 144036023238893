import { loadOrder } from './order_storage'

export function orderToUrlFormParams(urlString) {
  const url = new URL(urlString)
  const order = loadOrder()
  order.productVariants.forEach(orderItem => {
    url.searchParams.append("product_variants[][id]", orderItem.variant.id);
    url.searchParams.append("product_variants[][quantity]", orderItem.quantity);
    url.searchParams.append("product_variants[][for_cashback]", orderItem.forCashback ? 1 : 0);
    url.searchParams.append("product_variants[][for_promo_code]", orderItem.forPromoCode ? 1 : 0);
    url.searchParams.append("product_variants[][promo_code]", orderItem.promoCode);
    url.searchParams.append("product_variants[][gifted]", orderItem.gifted ? 1 : 0);
    url.searchParams.append("product_variants[][promo_id]", orderItem.promoId ? orderItem.promoId : '');
    orderItem.additions.forEach(addition => {
      url.searchParams.append("product_variants[][addition_ids][]", addition.id)
    });
  })
  url.searchParams.append("promo_code", order.promoCode);

  return url
}